.commentsForm {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0, 0, 0, 0.1);
  padding: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
}

.commentsForm h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  font-weight: 600;
  border-bottom-width: 1px;
  padding-bottom: 1rem;
}
.commentsForm__textarea {
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

.commentsForm__textarea textarea {
  padding: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  height: 10rem;
  outline: none;
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
}
.commentsForm__textarea textarea:focus {
  outline-color: 0.5px solid rgb(229, 231, 235);
}

.commentsForm__controls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.commentsForm__controls input[type='text'] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
}

.commentsForm__controls input[type='text']:focus {
  outline: 1px solid rgb(229, 231, 235);
}

.commentsForm__controls input[type='email'] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
}

.commentsForm__controls input[type='email']:focus {
  outline: 1px solid rgb(229, 231, 235);
}

.commentsForm__sub {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.commentsForm__button {
  display: inline-block;
  background: rgb(216, 39, 142);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  font-weight: 500;
  border-radius: 9999px;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.75rem;
  transition: transform 500ms ease;
}

.commentsForm__button:hover {
  transform: translateY(0.25rem);
}

.commentsForm__error {
  font-size: 1.05rem;
  line-height: 1.75;
  color: rgb(239, 68, 68);
}

.commentsForm__success {
  color: rgb(34, 197, 94);
  margin-top: 1.75rem;
  font-weight: 600;
  float: right;
  font-size: 1.25rem;
  line-height: 1.75;
}

@media screen and (max-width: 992px) {
  .commentsForm__controls {
    grid-template-columns: repeat(1, 1fr);
  }
}
