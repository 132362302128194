.featuredpostcard {
  position: relative;
  height: 18rem;
}

.featuredpostcard__1 {
  position: absolute;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 100%;
  height: 18rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.9), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  filter: brightness(34%);
}

.featuredpostcard__2 {
  position: absolute;
  border-radius: 0.5rem;
  background-position: center;
  height: 18rem;
  width: 100%;
  opacity: 0.5;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.907),
    rgba(0, 0, 0, 0.9),
    #000
  );
}

.featuredpostcard__info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.featuredpostcard__period {
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1rem;
  font-size: 0.95rem;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.featuredpostcard__title {
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
text-transform: uppercase;
  line-height: 2rem;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.featuredpostcard__aut {
    display: flex;
    /* align-items: center; */
    position: absolute;
    width: 100%;
    justify-content: center;
    bottom: 1.25rem;
}

.featuredpostcard__img {
  vertical-align: middle;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  border-radius: 9999px;
}

.featuredpostcard__autname {
  display: inline;
  vertical-align: middle;
  color: #fff;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  font-weight: 500;
  margin-right: 0.5rem;
}

.featuredpostcard__link {
  /* z-index: 10; */
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}
