#about{
  padding: 120px 0px 0px 0px;
  /* font-size: 38px; */
}

.about-area {
  background-color: #f9f9f9;
}

.single-well>a {
  display: block;
		transition: all .45s ease;
}

.single-well >a:hover {
	transform: scale(1.1) translateY(-5px);
}

.single-well ul li {
  color: #444;
  display: block;
  padding: 5px 0;
}

.single-well ul li i {
  color: #3EC1D5;
  padding-right: 10px;
  font-size: 12px;
}

.single-well p {
  color: #444;
}


@media screen and (max-width: 768px) {
.forall{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.single-well p {
text-decoration: 0;
display: block;
flex-wrap: wrap;

}
}
