#categories {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.6);
}

.categories__head {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  border-bottom: 1px solid rgb(77, 75, 75);
  padding-bottom: 1rem;
}

.categories__item {
  display: block;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
}
