section h2 {
  text-align: center;
  /* margin-bottom: 4rem; */
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.3rem;
}



.btn {
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 1rem 2rem;
  border: 1px solid transparent;
  font-weight: 500;
  transition: var(--transition);
}

.btn:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.btn-primary {
  background: #f75842;
  color: #fff;
}


.sectors__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.sector {
  background: #2e3267;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.sector__image img{ 
  width: 100%;
  display: block;
  object-fit: cover;
}

.sector:hover {
  background: transparent;
  border-color: #6c63ff;
}
.sector__info {
  padding: 2rem;
}

.sector__info p {
  margin: 1.2rem 0 2rem;
  font-size: 0.9rem;
}

.tsbfgh{
    background: linear-gradient(to right,rgba(166, 192, 188, 1), rgba(255, 255, 255, 0.4)), url('./../Images/blogm_new.jpg') no-repeat fixed center ;
background-size:cover;
background-position:center;
  }

  .tsbfgh > p{
  border: 1px solid #f75842;
  background: #f75842;
  }

.tsbf__intro{
  /* background: #2e3267; */
  margin: 0 7rem;
  /* min-height: 70vh; */
  font-size: 1.1rem;
  font-weight: bold;
  padding: 1rem;
  line-height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tsbf h2{
 margin-bottom: 0.3rem !important; 
}

.tsbf .row{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media screen and (max-width: 768px) {
.sectors__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.tsbf__intro{
  margin: 0 1rem;
}
}
@media screen and (max-width: 576px) {
.sectors__container {
    padding: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

}

@media screen and (width < 400px) {
.tsbf__intro{
  margin: auto;
  text-align: center;
  margin-bottom: 2rem;
}
.row{
  padding: 0.3rem !important;
}
}
