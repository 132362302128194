iframe {
  width: '300';
  height: '500';
}

@media screen and (width < 400px) {
  iframe {
    width: 100%;
    height: 40%;
  }
}
