.payment__modes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 76%;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .payments{
    padding: 0 !important;
  }  
}

@media screen and (max-width: 730px) {

  .payment__modes {
    display: grid !important;
    grid-template-columns: 1fr !important;
    /* gap: '1rem'; 
width: '76%'; 
margin: '0 auto' */
  }
  .payment__mode{
    margin: auto !important;
  }
}

@media screen and (max-width: 500px) {
  .payment__modes {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  .payment__mode{
    width: 76vw !important; 
    /* gap: '1rem'; 
margin: '0 auto' */
  }
}
