.adjacentposts {
  display: grid;
  /* grid-column: span 8 / span 8; */
  gap: 5rem;
  margin-bottom: 2rem;
}

.adjacentposts__button {
  position: relative;
  border-radius: 0.5rem;
  height: 18rem;
}

@media screen and (max-width: 768px) {
  .adjacentposts {
    grid-column: span 1 / span 1;
  }
}
