@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');


#blog,
::before {
  font-family: 'Montserrat', sans-serif;
  /* background-image: url('./blogpage.jpg'); */
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  /* position: fixed; */
  /* left: 0;
  top: 0; */
  z-index: -1;
  /* background-position: 50% 50%; */
  background-repeat: no-repeat;
  background-size: cover;
}


.text-shadow {
  text-shadow: 0px 2px 0px rgb(0, 0, 0/30%);
}

.adjacent-post .arrow-btn {
  transition: width 300ms ease;
  width: 50px;
}
.adjacent-post .arrow-btn:hover {
  width: 60px;
}

.react-multi-carousel-list .arrow-btn {
  transition: width 300ms ease;
  width: 50px;
}
.react-multi-carousel-list .arrow-btn:hover {
  width: 60px;
}

main {
  position: relative;
   /* top: 1.4rem;  */
     background: url('../../Images/blogbg.png') no-repeat fixed center;
  background-size: cover; 
}

main .container {
  display: grid;
  grid-template-columns: 80% 20vw;
  column-gap: 3rem;
  position: relative;
}

main .container .blog__contentRight {
  height: max-content;
  position: sticky;
  top: 2rem;
}

@media screen and (max-width:992px) {
  main .container {
    grid-template-columns: auto;
  }
  main .container .blog__contentRight{
    position: relative;
  }
  main {
    background-size: cover; 
  }
}