.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

#service{
  margin-top: 2rem;
  border-top: epx solid #eb5d1e;
  padding: 30px 0px 50px 0px;
  padding: 3rem;
}

.service-box {
background-color: #fff;
  padding: 2.5rem 1.3rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  animation: fade-up 100ms;

}

.service-box:hover .ico-circle {
  transition: all 500ms ease;
  color: #fff;
  background-color: #0078ff;
  box-shadow: 0 0 0 10px #cde1f8;
}

.service-box .service-ico {
  margin-bottom: 0.7rem;
  color: #1e1e1e;
  transition: all 500ms ease;
    text-align: center;

}

.service-box .service-ico:hover {
	transform: scale(1.1) translateY(-5px);
  /* font-size: 4rem; */
}

.service-box .s-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  padding: .4rem 0;
}

.service-box .s-description {
  color: #4e4e4e;
}

.service-box .service-content {
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .service-ico img{
width: 16rem;  
}
}

@media screen and (max-width: 991px) {
  .service-ico img{
width: 11rem;  
}
}

@media screen and (max-width: 768px) {
  .service-ico img{
    width: inherit;
  }
}

@media screen and (width < 400px) {
  #service {
    padding: 0 !important;
  }
  .section-title p{
    font-size: 1rem;
  }

  .section-title h2{
    font-size: 2rem;
  }

  .service-ico img{
    width: 7rem;
  }
  .s-title{
    font-size: 1rem !important;
  }
}
