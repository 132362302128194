.back-to-top {
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  left: 15px;
  bottom: 35px;
  background: #eb5d1e;
  border-radius: 50%;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  left: 2px;
}

.back-to-top:hover {
  color: #fff;
  background: #ec790ec0;
  transition: background 0.2s ease-in-out;
}


.whatsapp {
    color: #25D366;
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  left: 15px;
  bottom: 65px;
  /* transition: display 0.5s ease-in-out; */
		transition: all .45s ease;
  z-index: 99999;
}

.whatsapp i {
  font-size: 4px;
  position: absolute;
  bottom: 10px;
  /* left: -0.5vh; */
  		transition: all .45s ease;
    /* transition: display 0.5s ease-in-out; */
    z-index: 99999;
}

.whatsapp:hover {
  /* transition: background 0.2s ease-in-out; */
  	transform: scale(0.9) translateY(-5px);
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}