.adjacentPosts {
  position: absolute;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 100%;
  height: 18rem;
  filter: brightness(34%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.9), 0 1px 2px -1px rgb(0 0 0 / 0.1);	
}

.adjacentPosts1 {
  position: absolute;
  border-radius: 0.5rem;
  background-position: center;
  height: 18rem;
  width: 100%;
  opacity: 0.5;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.907),
    rgba(0, 0, 0, 0.9),
    #000
  );
    background-color: #000;
}

.adjacentPosts__info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.adjacentPosts__period {
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1rem;
  font-size: 0.95rem;
  /* z-index: 10; */
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.adjacentPosts__title {
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  /* z-index: 10; */
}

.adjacentPosts__link{
  z-index: 10;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}

.adjacentPosts__prev {
  position: absolute;
  text-align: center;
  border-radius: 9999px;
  background-color: rgb(219, 39, 119);
  cursor: pointer;
  left: 1rem;
  padding: 0.75rem;
  bottom: 1.25rem;
}

.adjacentPosts__prev svg {
  height: 1.5rem;
  width: 1.5rem;
  color: #fff;
}

.adjacentPosts__next {
  position: absolute;
  text-align: center;
  border-radius: 9999px;
  background-color: rgb(219, 39, 119);
  cursor: pointer;
  right: 1rem;
  padding: 0.75rem;
  bottom: 1.25rem;
}

.adjacentPosts__next svg {
  height: 1.5rem;
  width: 1.5rem;
  color: #fff;
}
