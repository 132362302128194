#postcard {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 15px -3px rgb(0, 0, 0, 0.1),
    0 4px 6px -4px rgb(0, 0, 0, 0.5);
}
@media screen and (max-width: 992px) {
  #postcard {
    padding: 0px;
  }
}
.post__featuredImage {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.6);
  padding-bottom: 20rem;
  margin-bottom: 1.5rem;
}

.post__image {
  object-position: top;
  position: absolute;
  height: 20rem;
  width: 100%;
  
  object-fit: cover;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgb(0, 0, 0, 0.1),
    0 4px 6px -4px rgb(0, 0, 0, 0.5);
}

.post__title {
  color: #000;
  transition: all 100ms ease;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  padding: 0 2rem;
  font-weight: 600;
}

.post__title:hover {
  color: rgb(216, 39, 142) ;
}

.post__extras {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
}

.post__author {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-right: 2rem;
}

.post__author p {
  display: inline;
  vertical-align: middle;
  color: rgb(55, 65, 81);
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0 !important;
}

.post__excerpt {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: gray;
  margin-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.post__timestamp {
  font-weight: 500;
  color: rgb(55, 65, 81);
  vertical-align: middle;
}

.calenderIcon {
  display: inline;
  height: 1.5rem;
  width: 1.5rem;
  color: rgb(236, 128, 146);
  margin-right: 0.5rem;
}

.post__readmore {
  display: inline-block;
  background: rgb(216, 39, 142);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  font-weight: 500;
  border-radius: 9999px;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.75rem;
  transition: transform 500ms ease;
}

.post__readmore:hover {
  transform: translateY(0.25rem);
}

@media screen and (max-width: 992px) {
  .post__excerpt {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  /* .post__extras {
    display: block;
  } */
  .post__author {
    width: 30%;
  }
  .post__readmore{
    margin-bottom: 2rem;
  }
  .post__timestamp span{
    display: inline !important;
  }
  .post__extras{
    vertical-align: middle;
  }
.post__title {
    font-size: 1.5rem;
}
.post__author p {
  font-size: 1rem;
  }
  }
  @media screen and (max-width: 312px) {
    .post__author p {
      font-size: 0.8rem;
      padding: 0;
      margin: 0;
      }
      .post__extras{
        padding: 1rem;
      }
  }