.page-item .active {
 background: black;
}

.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination .dtnumbers{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 0 !important;
  margin: 1px;
  background-color: #fff;
}

.pagination .dtnumbers li {
  color: #182848;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  /* background-color: red; */
}

.pagination .dtnumbers li:is(:hover, .active){
background: #182848;
color: #fff;
}