#team{
    padding: 150px 0px 100px 0px;
      background-color: #f3e7e2;
  padding: 3rem;
}

.team {
  /* background: #fff; */
  padding: 60px 0;
}

.team .member {
  text-align: center;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #17181a;
  position: relative;
  overflow: hidden;
		transition: all .45s ease;
}
.team .member:hover {
  	transform: scale(0.9) translateY(-5px);
}


.team .member .member-info {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

.team .member .member-info-content {
  position: absolute;
  left: 50px;
  right: 0;
  bottom: 0;
  transition: bottom 0.4s;
}

.team .member .member-info-content h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

.team .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

.team .member .social {
  position: absolute;
  left: -50px;
  top: 0;
  bottom: 0;
  width: 50px;
  transition: left ease-in-out 0.3s;
  background: rgba(78, 64, 57, 0.6);
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  display: block;
  color: #fff;
  margin-top: 15px;
}

.team .member .social a:nth-child(1):hover {
  color: #25D366;
}
.team .member .social a:nth-child(2):hover {
  color: #1e5feb;
}
.team .member .social a:nth-child(3):hover {
  color: #DB4437;
}
.team .member .social a:nth-child(4):hover {
  color: #3a6ede;
}


.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member:hover .member-info {
  background: linear-gradient(0deg, rgba(219, 86, 19, 0.95) 0%, rgba(134, 92, 71, 0.329) 15%, rgba(85, 202, 7, 0) 100%);
  opacity: 1;
  transition: 0.4s;
}

.team .member:hover .member-info-content {
  bottom: 30px;
  transition: bottom 0.4s;
}

.team .member:hover .social {
  left: 0;
  transition: left ease-in-out 0.3s;
}


@media screen and (max-width: 768px) {
.team .member {
  background-color: transparent;
}
}

@media screen and (width < 400px) {
#team{
  padding: 0;
}
}
