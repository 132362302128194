
 /* MAIN HERO */
section {
    display: block;
    z-index: -1;
}

.hcaption{
    display: flex;
  flex-direction: column;
  align-items: center ;
  justify-content: center;
  bottom: 40%;
}

.hcaption  h2{
  font-weight: 700;
  font-size: 46px;
}


@media screen and (max-width: 1111px) {
  .hcaption  h2{
  font-weight: 20%;
  font-size: 26px;
}
.hcaption{
  bottom: 20%;
}
}



@media screen and (max-width: 500px) {
  .hcaption  h2{
  font-size: 18px;
}
  .hcaption p {
    font-size: 13px;
  }
  .hcaption{
    bottom: 10px;
  }
}

@media screen and (max-width: 400px) {
  .hcaption  h2{
  /* font-weight: 10%; */
  font-size: 14px;
}
  .hcaption p {
    font-size: 9px;
  }
}
