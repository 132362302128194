/* #clients{
    width: 100vw;
    height: 1360px;
  padding: 40px 0;
} */

.owl-stage{
      background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)),
    url('../../Images/call-to-action-bg.jpg')  center center;
  }

.clients .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 20px; */
}

/* .clients .owl-item img { */
.owl-stage img {
  padding: 20px;
  height: 210px;
  /* width:110px; */
  /* opacity: 0.5; */
  /* transition: 0.3s; */
}


/* .clients .owl-item img:hover {
  opacity: 1;
}

.clients .owl-nav,
.clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.clients .owl-dot.active {
  background-color: #eb5d1e !important;
}


.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
} */