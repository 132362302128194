.btn {
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 1rem 2rem;
  border: 1px solid transparent;
  font-weight: 500;
  transition: var(--transition);
}

.btn:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.btn-primary {
  background: #f75842;
  color: #fff;
}

.courses__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.course {
  background: #2e3267;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.course__image img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.course:hover {
  background: rgb(10, 46, 165);
  border-color: #6c63ff;
  color: #000 !important;
}
.course__info {
  padding: 2rem;
  color: #fff;
}

.course__info p {
  margin: 1.2rem 0 2rem;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .courses__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .courses__container {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}
