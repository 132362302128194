.comments {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
    padding: 2rem;
    padding-bottom: 3rem;
    margin-bottom: 2rem;
}

.comments h3 {
    border-bottom-width: 1px;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.comments__details {
    border-bottom-width: 1px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-color: rgb(243, 244, 246);
    background: rgba(252, 64, 174, 0.5);
}

.comments__details p:first-child {
    margin-bottom: 1rem;
}

.comments__details p:first-child span {
    font-weight: 600;
}

.comments__details p:last-child{
width: 100%;
white-space: pre-line;
color: rgb(26, 31, 37);
}