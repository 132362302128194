    .dtpage{
      min-height: 100vh;
      /* min-width: 100vw; */
      background: linear-gradient(to bottom,rgb(243, 234, 217), rgba(206, 199, 199, 0.795)), url('./DunamisTravels/dtbg2.png') no-repeat fixed center;
      background-size: cover;
      a {
        color:#fff;
  text-decoration: none;
}

ul {
  list-style: none;
}

::selection {
  background: #7f71ff;
  color: #fff;
}

::-webkit-scrollbar {
  display: none;
}
}  

#dtmain{
  width: 100%;
  min-height: calc(100vh - 250px);
}


nav .menu-lists {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
user-select: none;
}

.catNavLink{
  background: rgba(255, 153, 0, 0.7);;
}

nav .menu-lists li {
  padding: 5px 15px;
  color: #ffffff;
  cursor: pointer;
  margin: 3px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

nav .menu-lists li:is(:hover, .active) {
  background: rgba(0, 0, 0, 0.05);
  color: #fff;
  transition: all ease 0.3s;
}    

/* ------------MAIN POSTS------------- */

/* POSTS */
.dtposts-container {
  width: 95%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.dtposts-box {
  width: 100%;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05);
  background: #fff;
}

.dtposts-img{
  width: 100%;
  height: 185px;
  overflow: hidden;
  position: relative;
}

.dtposts-img img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.dtposts-img:hover img {
  transition: all 1s linear;
  filter: invert(1);
}

.dtposts-img .dtcategories {
  position: absolute;
  left: 0;
  top: 10px;
  display: flex;
  gap: 5px;
}

.dtposts-img .dtcategories .category {
  padding: 2px 10px;
  font-size: 0.9rem;
  color: #fff;
}

.dtposts-text h3{
  font-size: 1.1rem;
  text-transform: capitalize;
  color: #000;
  font-weight: 500 !important;
  margin: 5px 0;
  line-height: 25px;
  transition: all 0.3s linear;
}

.dtposts-text h3:hover{
  color: #0101b7;
}

.dtposts-text .dtcategory-time{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-top: 5px;
  font-weight: 500;

}

.dtposts-text .dtcategory-time .dtpublished-time{
  color: #420000;
}

.dtposts-text .dtexcerpt{
  font-size: 0.9rem;
  color: #888;
}

/*------------------- SEARCH------------------------- */
.search{
  width: 50%;
  margin: 20px auto;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search input{
  width: 100%;
  height: 100%;
  border:none;
outline: none;
background: transparent;
padding: 0 20px;
font-size: 0.95rem;
}

.search button{
  border: none;
  outline: none;
  background: #182848;
  width: 80px;
  height: inherit;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}


/* -------------POSTVIEW------  */
/* ARTICLE */
.dtpost-container{
  width: 95%;
  margin: 1rem auto;
}

.dtpost-container article .title{
  text-align: center;
  font-size: 2rem;
  text-transform: capitalize;
  color: #182848;
}

.dtpost-container article .dtpublished-time{
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #420000;
}

.dtpost-container article .dtpublished-time i{
  margin-right: 7px;
}

.dtpost-container article .content{
  margin-bottom: 20px;
}

.dtpost-container article .content :is(img, video){
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10px 0;
}

.dtpost-container article .content :is(h1, h2, h3, h4, h5, h6, p){
  margin: 10px 0;
line-height: 1.8rem;
}


/*------------------ COMMENTS--------------------- */
.comments{
  margin-top: 50px;
  margin-bottom: 10px;
  width: 100%;
}

.comments h2{
  color: #4b6cb7;
  padding: 5px 0;
}

.comments form :is(input, textarea){
  width: 100%;
  border: none;
  outline: none;
  background: #fff;
  padding: 10px 20px;
  font-size: 0.95rem;
  margin: 10px 0;
  resize: none;
   border: 1px solid #000000a6; 
}

.comments form input{
  height: 50px;
  border: 1px solid #000000a6;
}

.comments form .check{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.comments form .check input[type="checkbox"]{
  width: 25px;
  height: 25px;
}

.comments form button{
  display: block;
  border: none;
  outline: none;
  background: #4b6cb7;
  padding: 10px 30px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

.comments .content{
  margin-top: 20px;
  background: #4b6cb7;
  color: #fff;
  border-radius: 5px;
padding: 10px 20px;  
}

.comments .content i{
  margin-right: 5px;
}

.comments .content p{
  color: #ddd;
  font-size: 0.9rem;
  margin-top: 3px;
}


/* -----------PAGINATION----------------- */
.dtpagination{
  width: 95%;
  margin: 3px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.dtpagination .dtprev{
text-transform: capitalize;
cursor: pointer;
color: #182848;
font-weight: 500;
transition: all 0.3s linear;
margin-left:3rem;
}

.dtpagination .dtnext {
text-transform: capitalize;
cursor: pointer;
color: #182848;
font-weight: 500;
transition: all 0.3s linear;
margin-right:3rem;
}

.dtpagination .dtnumbers{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 0 !important;
  margin: 1px;
  color: #182848;

}

.dtpagination .dtnumbers li {
  color: #182848;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
}

.dtpagination .dtnumbers li:is(:hover, .active){
background: #182848;
color: #fff;
}


/* ---------------FOOTER ------------------------ */
#dtfooter{
  /* background: rgb(255, 153, 0); */
  background: rgba(255, 153, 0, 0.7);;
  width: 100%;
  /* min-height: 70px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-top: 1 px solid rgba(0, 0, 0, 0.05);
  margin-top: 5px;
}

#dtfooter span {
  color: #131313;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 5px;
}

#dtfooter .dev a{
  color: #f1eaea;
}

.footer-social{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin: 5px;
}

.footer-social a{
  width: 35px;
  height: 35px;
  color: #ffffff;
  border: 1.5px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-social a:hover{
  color: crimson;
  border-color: crimson;
  transition: all 0.3s ease;
}

@media (max-width:750px){
  #dtfooter span {
    display: block;
    /* font-size: 0.5rem; */
  }
  #dtfooter span .dev{
    display: flex;
    /* font-size: 0.5rem; */
  }
}

@media (max-width:330px){
  .dtposts-container{
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}