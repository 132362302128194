.author{
margin-top: 5rem;
margin-bottom: 2rem;
padding: 3rem;
position: relative;
border-radius: 0.5rem;
background: rgba(0, 0, 0, 0.5);

/* opacity: 0.2; */
}

.author__image {
 position: absolute;
left: 0;
right: 0;
 top: -3rem;  
}

.author h3 {
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.author p {
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.75rem;
}