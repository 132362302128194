#footer {
  background: #fff;
  padding: 90px 0 30px 0;
  color: #0a0a0a;
  font-size: 14px;
  background: #fef8f5;
}


#footer .footer-newsletter {
  padding: 50px 0;
  background: #fef8f5;
  text-align: center;
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #4e4039;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 4px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #eb5d1e;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #c54811;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  border-top: #25D366;
  /* background: linear-gradient(to top, #c54811, rgba(146, 180, 21, 0));; */
  background: rgb(236, 236, 227);
}

.footer-top {
    border-top: 3px solid #eb5d1e;
/* background: url('../call-to-action-bg.jpg'); */
  /* background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)),
    url('./call-to-action-bg.jpg') fixed center center; */
  /* background-size: cover; */
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #5c5c5c;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #212529;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #f39e7a;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #5c5c5c;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
	transition: all .35s ease;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #eb5d1e;
	transform: translateX(-8px);
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ffffff;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ef7f4d;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  text-align: center;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #212529;
}

#footer .credits a {
  color: #0D6EFD;
}

@media (max-width: 575px) {
  #footer .copyright, #footer .credits {
    float: none;
    -moz-text-align-last: center;
    text-align-last: center;
    padding: 3px 0;
  }
}

#footer .footer-newsletter p {
  color: red;
  display: none;
} 

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: .7;
  z-index: 100;
}


@media screen and (max-width: 768px) {
#footer {
  text-align: center;
}

.vdown{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
}
