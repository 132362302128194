/* .topbar ul {
    /*   display: flex;
  flex-direction: row; 
    /* margin: auto auto; 
    /* align-items: center;
    justify-content: space-evenly; 
} */

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* position: sticky; */
  top: 0;
  z-index: 100;
  background-color: white;
}

.topbar ul {
  display: flex;
  margin: auto auto;
  list-style: none;
}

.topbar__left ul li {
  margin: 0.3rem 0.1rem;
  padding: 0.3rem;
  font-size: small;
  border-radius: 10px;
}

.topbar__left ul li:last-child {
  display: none;
}

.topbar__right ul {
  margin-right: 2rem;
}

.topbar__right ul li {
  background-color: #eb5d1e;
  font-weight: bold;
  color: #fff !important;
  margin: 0.3rem 0.1rem;
  padding: 0.4rem 1rem;
  font-size: small;
  border-radius: 10px;
}

.topbar__right ul li:hover {
  background: transparent;
  color: black;
  border: 1px solid #eb5d1e;
}

.topbar__right ul li:last-child {
  background-color: red;
  color: #fff;
}

.topbar__right ul li:last-child:hover {
  background-color: #fff;
  border: 1px solid red;
}

@media screen and (max-width: 1029px) {
  .emailadd {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .topbar__right {
    display: none;
  }
  .topbar__left ul li:last-child {
    display: inline;
  }
  .topbar__left {
    margin: auto auto !important;
  }
}

@media screen and (max-width: 600px) {
  .emailadd {
    display: none;
  }

  .topbar ul {
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 450px) {
  .topbar ul {
    /* margin: 0 !important; */
    padding: 0 !important;
  }
}

@media screen and (max-width: 355px) { 
  .topbar__left ul li {
    display: none;
    margin-left: 0;
  }
  .topbar__left ul li:last-child {
    display: none;
  }
}
