#postdetails {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 15px -3px rgb(0, 0, 0, 0.1),
    0 4px 6px -4px rgb(0, 0, 0, 0.5);
}

.postdetails__featuredImage {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.6);
  margin-bottom: 1.5rem;
  /* object-fit: contain; */
}

.postdetails__image {
  /* object-fit: cover; */
  object-fit: contain;
  object-position: top;
  /* height: 25rem; */
  width: 100%;
  height: auto;
  /* width: 20%; */
  /* max-width: 100%; */

  border-radius: 0.5rem;
  /* overflow: hidden; */
  box-shadow: 0 10px 15px -3px rgb(0, 0, 0, 0.1),
    0 4px 6px -4px rgb(0, 0, 0, 0.5);
}

.postdetails__extras {
  padding-left: 0;
  padding-right: 0;
}
.postdetails__extras h1 {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.postdetails__inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 2rem;
}

.postdetails__author {
  display: flex;
    justify-content: center;
    align-items: center;
  width: auto;
  margin-bottom: 0 !important;
  margin-left: 2rem;
  margin-right: 2rem;
}

.postdetails__author p {
  display: inline;
  vertical-align: middle;
  color: rgb(55, 65, 81);
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
    margin-left: 1rem;
  margin-bottom: 0 !important;
}

.postdetails__timestamp {
  font-weight: 500;
  color: rgb(55, 65, 81);
  vertical-align: middle;
}

.calenderIcon {
  display: inline;
  height: 1.5rem;
  width: 1.5rem;
  color: rgb(236, 128, 146);
  margin-right: 0.5rem;
}

.socialshare{
  display: none;
}

@media screen and (max-width: 768px) {
  .postdetails__author {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .postdetails__image{
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .postdetails__extras {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

  @media screen and (max-width: 360px) {
    .postdetails__inner{
      flex-direction: column;
      gap: 0.5rem;
    }
    .postdetails__author {
margin: 0;
}

.postdetails__author p {
  margin: 0;
  font-size: 1rem;
}
  }