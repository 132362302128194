#postwidget {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.6);
}

#postwidget h3 {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  font-weight: 600;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(126, 121, 121);
}

.postwidget__details {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.postwidget__image {
  width: 3.5rem;
  flex: none;
}

.postwidget__extras {
  flex-grow: 1;
  margin-left: 1rem;
}

.postwidget__extras p {
  color: rgb(107, 114, 128);
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 1rem;
}
