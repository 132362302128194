.catpage {
  margin-right: auto;
  margin-left: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 2rem;
}

.catpage__inner {
    display: grid;
    grid-template-columns: 80% 20vw;
    column-gap: 3rem;
    position: relative;
}

.catpage__cat {
  position: sticky;
  top: 2rem;
}

@media screen and (max-width: 1023px) {
  .catpage__right,
  .catpage__left,
  .catpage__inner {
    grid-template-columns: auto;
  }

  .catpage__cat {
    position: relative;
  }
}
