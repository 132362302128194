.leftarrow{
    position: absolute;
    left: 0;
    text-align: center;
    padding: 0.75rem;
    cursor: pointer;
    background-color: rgb(219, 39, 119);
    border-radius: 9999px;

  /* bottom: 1.25rem; */
}

.leftarrow svg{
  height: 1.5rem;
  width: 1.5rem;
  color: #fff;
}

.rightarrow{
    position: absolute;
    right: 0;
    text-align: center;
    padding: 0.75rem;
    cursor: pointer;
    background-color: rgb(219, 39, 119);
    border-radius: 9999px;

  /* bottom: 1.25rem; */
}
 .rightarrow svg {
  height: 1.5rem;
  width: 1.5rem;
  color: #fff;
}












/* STORIES */
.stories {
  display: flex;
  justify-content: space-between;
  height: 12rem;
  margin: 2rem 6rem;
  gap: 0.5rem;
}

.story {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 0.75rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* .stories .story::before {
  content: '';
  display: block;
  width: 100%;
  height: 5rem;
  background-color: aqua; */
  /* background: linear-gradient(transparent, rgba(0, 0, 0, 0.75)); */
  /* position: absolute;
  bottom: 0;
} */

.stories .story .name {
  z-index: 2;
}

/* .stories .story:nth-child(1) {
  background: 
  /* url('./images/story-1.jpg')  *
  no-repeat center center/cover;
}

.stories .story:nth-child(2) {
  background: 
/* url('./images/story-2.jpg')  *
no-repeat center center/cover;
}
.stories .story:nth-child(3) {
  background: 
  /* url('./images/story-3.jpg')  *
  no-repeat center center/cover;
}
.stories .story:nth-child(4) {
  background: 
  /* url('./images/story-4.jpg')  *
  no-repeat center center/cover;
}
.stories .story:nth-child(5) {
  background: 
  /* url('./images/story-5.jpg')  *
  no-repeat center center/cover;
}
.stories .story:nth-child(6) {
  background: red
  /* url('./images/story-6.jpg') *
   no-repeat center center/cover;
}

.story .profile-photo {
  width: 2rem;
  height: 2rem;
  align-self: start;
  border: 3px solid hsl(252, 75%, 60%);
} */