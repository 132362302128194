.post {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 2rem;
  background-image: url('../../Images//blogbg.jpg');
  width: 100%;
  height: 100vh;
   z-index: -1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover; 
}

.post__section {
  display: grid;
  grid-template-columns: 80% 20vw;
  column-gap: 3rem;
  position: relative;
}

main {
  position: relative;
   /* top: 1.4rem;  */
    /* background-image: url('./blogbg.jpg'); */
}

main .container {
  display: grid;
  grid-template-columns: 80% 20vw;
  column-gap: 3rem;
  position: relative;
}

main .container .blog__contentRight {
  height: max-content;
  position: sticky;
  top: 2rem;
  bottom: 0;
}

@media screen and (max-width:992px) {
  main .container {
    grid-template-columns: auto;
  }
  main .container .blog__contentRight{
    position: relative;
  }
}