header {
  position: sticky;
  top: 0;
  /* width: 100vw; */
  z-index: 100;
}

@media screen and (max-width: 768px) {
  img.logo {
    margin-top: 0;
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  img.logo {
    width: 50%;
  }
}
