/* **********************************TS 4 HUMANITY ***************************************** */
.ts4humanity{
    min-height: 100vh;
    /* background-color: rgb(53, 8, 66); */
        background: linear-gradient(to right,rgba(166, 192, 188, 1), rgba(255, 255, 255, 0.4)), url('../Images/blogm_new.jpg') no-repeat ;
    background-size:cover;
    background-position:center;
    /* display: none; */
}
.ts4humanity > p{
    border: 1px solid #f75842;
    background: #f75842;
    }