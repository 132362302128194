* {
  box-sizing: border-box;
  margin: 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.section-title p {
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
  font-size: 32px;
  font-weight: 700;
  color: #4e4039;
}

section {
  padding: 6rem 0;
}

section:last-child{
    padding: 0;
}

section h2 {
  text-align: center;
  margin-bottom: 4rem;
}


a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

a:hover {
  /* color: #0b6bd3; */
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: 'Raleway', sans-serif;
    line-height: 1.2;
}

body {
animation-name: fade-in;
animation-duration: 2s;
  background: linear-gradient(to right,rgba(166, 192, 188, 1), rgba(255, 255, 255, 0.8)), url('./Images/bg.png') no-repeat fixed center;
  background-size:contain;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fade-in {
	from {opacity: 0;}
	to{opacity: 1;}
}

@-webkit-keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}
@keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}
@-webkit-keyframes burst
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    90%
    {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);

        opacity: 0;
    }
}
@keyframes burst
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    90%
    {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);

        opacity: 0;
    }
}
@-webkit-keyframes flashing
{
    0%
    {
        opacity: 1;
    }
    45%
    {
        opacity: 0;
    }
    90%
    {
        opacity: 1;
    }
}
@keyframes flashing
{
    0%
    {
        opacity: 1;
    }
    45%
    {
        opacity: 0;
    }
    90%
    {
        opacity: 1;
    }
}
@-webkit-keyframes fade-left
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);

        opacity: 0;
    }
}
@keyframes fade-left
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-right
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);

        opacity: 0;
    }
}
@keyframes fade-right
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-up
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);

        opacity: 0;
    }
}
@keyframes fade-up
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-down
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);

        opacity: 0;
    }
}
@keyframes fade-down
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);

        opacity: 0;
    }
}
@-webkit-keyframes tada
{
    from
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    10%,
    20%
    {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
    }

    30%,
    50%,
    70%,
    90%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }

    40%,
    60%,
    80%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    }

    to
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}

@keyframes tada
{
    from
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    10%,
    20%
    {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
    }

    30%,
    50%,
    70%,
    90%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }

    40%,
    60%,
    80%
    {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
                transform: rotate3d(0, 0, 1, -10deg);
    }

    to
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}
.bx-spin
{
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}
.bx-spin-hover:hover
{
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

.bx-tada
{
    -webkit-animation: tada 1.5s ease infinite;
            animation: tada 1.5s ease infinite;
}
.bx-tada-hover:hover
{
    -webkit-animation: tada 1.5s ease infinite;
            animation: tada 1.5s ease infinite;
}

.bx-flashing
{
    -webkit-animation: flashing 1.5s infinite linear;
            animation: flashing 1.5s infinite linear;
}
.bx-flashing-hover:hover
{
    -webkit-animation: flashing 1.5s infinite linear;
            animation: flashing 1.5s infinite linear;
}

.bx-burst
{
    -webkit-animation: burst 1.5s infinite linear;
            animation: burst 1.5s infinite linear;
}
.bx-burst-hover:hover
{
    -webkit-animation: burst 1.5s infinite linear;
            animation: burst 1.5s infinite linear;
}
.bx-fade-up
{
    -webkit-animation: fade-up 1.5s infinite linear;
            animation: fade-up 1.5s infinite linear;
}
.bx-fade-up-hover:hover
{
    -webkit-animation: fade-up 1.5s infinite linear;
            animation: fade-up 1.5s infinite linear;
}
.bx-fade-down
{
    -webkit-animation: fade-down 1.5s infinite linear;
            animation: fade-down 1.5s infinite linear;
}
.bx-fade-down-hover:hover
{
    -webkit-animation: fade-down 1.5s infinite linear;
            animation: fade-down 1.5s infinite linear;
}
.bx-fade-left
{
    -webkit-animation: fade-left 1.5s infinite linear;
            animation: fade-left 1.5s infinite linear;
}
.bx-fade-left-hover:hover
{
    -webkit-animation: fade-left 1.5s infinite linear;
            animation: fade-left 1.5s infinite linear;
}
.bx-fade-right
{
    -webkit-animation: fade-right 1.5s infinite linear;
            animation: fade-right 1.5s infinite linear;
}
.bx-fade-right-hover:hover
{
    -webkit-animation: fade-right 1.5s infinite linear;
            animation: fade-right 1.5s infinite linear;
}