@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700");

/* TAN-COLOR */
header .social-links .social-link:hover {
    color: #F5AE51;
}

header .logo:before {
    background-color: #F5AE51;
}

header .menu-button:before {
    background-color: #F5AE51;
}

.main-btn {
    color: #F5AE51;
    border-color: #F5AE51;
}

.main-btn:hover {
    background-color: #F5AE51;
    border-color: #F5AE51;
}

blockquote {
    border-color: #F5AE51;
}

.sub-heading h3:before {
    background: #F5AE51;
}

.preloader .loading-text span:before {
    color: #F5AE51;
}

.section-about .about-info p strong {
    color: #F5AE51;
}

.section-about .about-info .about-list li .title:before {
    background-color: #F5AE51;
}

.section-about .services .service-item i {
    color: #F5AE51;
}

.section-about .testimonials .testimonial-item .description:before {
    color: #F5AE51;
}

.section-about .testimonials .testimonial-item .rating li {
    color: #F5AE51;
}

.section-blog .blog-item .post-content .cat {
    color: #F5AE51;
}

.section-contact .contact-detail .contact-info li .title:before {
    background-color: #F5AE51;
}

.section-contact .contact-detail .social-media h4:before {
    background-color: #F5AE51;
}

.page.section-home .banner-text .animate-title {
    color: #F5AE51;
}

.section-portfolio .portfolio-filter ul li.active {
    background-color: #F5AE51;
}

.section-portfolio .portfolio-items .item figure {
    background: #F5AE51;
}

.section-resume .experience,
.section-resume .education {
    border-color: #F5AE51;
}

.section-resume .experience h5,
.section-resume .education h5 {
    color: #F5AE51;
}

.section-resume .experience .event:after,
.section-resume .education .event:after {
    -webkit-box-shadow: 0 0 0 4px #F5AE51;
    box-shadow: 0 0 0 4px #F5AE51;
}

.section-resume .skills .skill-item .progress .progress-bar:after {
    border-color: #F5AE51;
}

.section-resume .skills .skill-item .progress .progress-bar {
    background-color: #F5AE51;
}

header .back-button:before {
    background-color: #F5AE51;
}

.single-blog .blog-category li {
    border-color: #F5AE51;
}

.single-blog .blog-category li a {
    color: #F5AE51;
}
/*Preloader*/

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #1d1e21;
	z-index: 9999;
}

.loading-text {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	width: 100%;
	height: 100px;
	line-height: 100px;
}

.loading-text span {
	display: inline-block;
	position: relative;
	overflow: hidden;
	color: #000;
	font-size: 22px;
}

.loading-text span:before {
	content: attr(data-preloader);
	position: absolute;
	top: 0;
	left: 0;
	color: #e1e1e1;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-animation-name: preload-animation;
	animation-name: preload-animation;
}

.loading-text span:nth-child(2):before {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.loading-text span:nth-child(3):before {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.loading-text span:nth-child(4):before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.loading-text span:nth-child(5):before {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.loading-text span:nth-child(6):before {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.loading-text span:nth-child(7):before {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

@-webkit-keyframes preload-animation {
	0%,
	100% {
		-webkit-transform: rotateY(-90deg) scale(1);
		transform: rotateY(-90deg) scale(1);
		opacity: 0;
	}
	20%,
	50% {
		-webkit-transform: rotateY(0) scale(1);
		transform: rotateY(0) scale(1);
		opacity: 1;
	}
	75% {
		-webkit-transform: rotateY(0) scale(1.2);
		transform: rotateY(0) scale(1.2);
		opacity: 0;
	}
}

@keyframes preload-animation {
	0%,
	100% {
		-webkit-transform: rotateY(-90deg) scale(1);
		transform: rotateY(-90deg) scale(1);
		opacity: 0;
	}
	20%,
	50% {
		-webkit-transform: rotateY(0) scale(1);
		transform: rotateY(0) scale(1);
		opacity: 1;
	}
	75% {
		-webkit-transform: rotateY(0) scale(1.2);
		transform: rotateY(0) scale(1.2);
		opacity: 0;
	}
}
.fadeOut{
	animation-name:fadeOut
}

@keyframes 
fadeOut{
	0%{opacity:1}100%{opacity:0}
}

.page-heading {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 50px;
}

.page-heading h2 {
	position: relative;
	font-size: 32px;
	letter-spacing: 1px;
	text-transform: capitalize;
	font-family: "Source Serif Pro", serif;
	color: #e1e1e1;
	font-weight: 700;
	line-height: 100%;
	text-transform: uppercase;
}

.page-heading h2:before {
	position: absolute;
	content: attr(data-shadow);
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 62px;
	text-transform: capitalize;
	z-index: -1;
	color: #e1e1e1;
	font-weight: 700;
	opacity: .04;
	text-transform: uppercase;
}

.sub-heading {
	margin-bottom: 40px;
	text-align: center;
	overflow: hidden;
}

.sub-heading h3 {
	color: #e1e1e1;
	font-weight: 600;
	font-size: 28px;
	display: inline-block;
	position: relative;
	padding-bottom: 5px;
}

.sub-heading h3:before {
	content: " ";
	background: cyan;
	height: 4px;
	position: absolute;
	bottom: 0%;
	left: 50%;
	width: 50px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.main-btn {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	display: inline-block;
	position: relative;
	text-align: center;
	color: #e1e1e1;
	border: 2px solid #e1e1e1;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background-color: transparent;
	cursor: pointer;
	line-height: 40px;
	padding-left: 20px;
	padding-right: 20px;
	-webkit-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

.main-btn:hover {
	color: #1d1e21;
}

/*======== 3. Layout ========*/


/*Pages Navigation*/

header .nav-menu {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 47vh;
    padding: 5vh;
    pointer-events: none;
    opacity: 0;
    background: transparent;
    -webkit-transition: opacity 1.2s, -webkit-transform 1.2s;
    transition: opacity 1.2s, -webkit-transform 1.2s;
    -o-transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s, -webkit-transform 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -o-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transform: translate3d(0, 150px, 0);
    transform: translate3d(0, 150px, 0);
}

header .nav-menu.nav-menu--open {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

header .nav-menu.nav-menu--open .social-links {
    opacity: 1;
    -webkit-transition-delay: 0.35s;
    -o-transition-delay: 0.35s;
    transition-delay: 0.35s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

header .nav-menu.nav-menu--open .copy {
    opacity: 1;
    -webkit-transition-delay: 0.65s;
    -o-transition-delay: 0.65s;
    transition-delay: 0.65s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

header .nav-menu .social-links {
    opacity: 0;
    -webkit-transition: opacity 1.2s, -webkit-transform 1.2s;
    transition: opacity 1.2s, -webkit-transform 1.2s;
    -o-transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s, -webkit-transform 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -o-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
}

header .nav-menu .copy {
    opacity: 0;
    -webkit-transition: opacity 1.2s, -webkit-transform 1.2s;
    transition: opacity 1.2s, -webkit-transform 1.2s;
    -o-transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s, -webkit-transform 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -o-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
}

header .nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

header .nav-menu {
	text-align: center;
}

header .nav-menu .nav-menu--link {
	display: inline-block;
	padding: 0 0.2em;
	margin: 0px 15px;
	text-align: center;
	font-size: 1.2em;
	color: #888;
	position: relative;
}

header .nav-menu .nav-menu--link:hover,
header .nav-menu .nav-menu--link.link--active {
	color: #fff;
}

header .nav-menu .nav-menu--link i {
	text-align: center;
}

header .nav-menu .nav-menu--link span {
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: 700;
	padding-top: 2px;
	line-height: 100%;
	text-align: center;
}

header .nav-menu .social-links .social-link {
	font-size: 24px;
	display: inline-block;
	margin-left: 18px;
	margin-right: 18px;
	color: #888;
}

header .nav-menu .social-links .social-link:hover {
	color: #e1e1e1;
}

header .nav-menu .copy {
	color: #888;
	font-size: 15px;
	margin-bottom: 0;
}

header .cv__logo {
	position: absolute;
	display: block;
	z-index: 1000;
	top: 20px;
	left: 20px;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1em;
	text-transform: uppercase;
	text-shadow: 0px 1px 1px black;
	color: #fff;
	height: 50px;
	width: 50px;
	overflow: hidden;
}

header .cv__logo:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #e1e1e1;
	opacity: .6;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	z-index: -1;
}

header .cv__logo span {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

header .menu-button {
	position: absolute;
	display: inline-block;
	top: 20px;
	right: 20px;
	height: 50px;
	width: 50px;
	z-index: 1000;
	cursor: pointer;
	padding: 10px 8px;
}

header .menu-button:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #e1e1e1;
	opacity: .6;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	z-index: -1;
}

header .menu-button span {
	display: block;
	position: absolute;
	height: 3px;
	width: 35px;
	background-color: #fff;
	left: 7.5px;
	-webkit-transition: -webkit-transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	transition: -webkit-transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	-o-transition: transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	transition: transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	transition: transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4), -webkit-transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
}

header .menu-button span:nth-child(1) {
	top: 12px;
	-webkit-transform-origin: 0px 0px;
	-ms-transform-origin: 0px 0px;
	transform-origin: 0px 0px;
}

header .menu-button span:nth-child(2) {
	top: 24px;
	right: 7.5px;
	width: 30px;
}

header .menu-button span:nth-child(3) {
	top: 36px;
	-webkit-transform-origin: 0% 100%;
	-ms-transform-origin: 0% 100%;
	transform-origin: 0% 100%;
}

header .menu-button.menu-button--open span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	left: 14px;
}

header .menu-button.menu-button--open span:nth-child(2) {
	opacity: 0;
}

header .menu-button.menu-button--open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	left: 14px;
}

/* Pages stack */

.pages-stack {
	z-index: 100;
	pointer-events: none;
	-webkit-perspective: 1200px;
	perspective: 1200px;
	-webkit-perspective-origin: 50% -50%;
	perspective-origin: 50% -50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.pages-stack--open .page {
	cursor: pointer;
	-webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
	transition: opacity 0.45s, -webkit-transform 0.45s;
	-o-transition: transform 0.45s, opacity 0.45s;
	transition: transform 0.45s, opacity 0.45s;
	transition: transform 0.45s, opacity 0.45s, -webkit-transform 0.45s;
	-webkit-transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
	-o-transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
	transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
	-webkit-box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
	box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.page {
	position: relative;
	z-index: 5;
	/* overflow: hidden; */
	/* overflow-y: hidden; */
	width: 100%;
	height: 100vh;
	pointer-events: auto;
	background: #2a2b30;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

/* .page--inactive {
	position: absolute;
	z-index: 0;
	top: 0;
	opacity: 0;
} */

.pages-stack--open a,
.pages-stack--open .portfolio-filter,
.pages-stack--open button {
	pointer-events: none;
	cursor: default;
}


/*======== 4. Home Section ========*/

[class*="letter"] {
	display: block;
	position: relative;
	-webkit-box-flex: 0;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none;
	white-space: pre;
}

/* Text Slideshow */
.text-slideshow,
.text-slideshow .text-slide {
	position: relative;
	width: 100%;
	height: 100%;
}

.text-slideshow .text-slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-slideshow .text-slide:not(.text-slide--current) {
	position: absolute;
	top: 0;
	pointer-events: none;
	opacity: 0;
}

.letter-effect {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.page.section-home {
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
}

.page.section-home:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #181818;
	opacity: .4;
	z-index: 0;
}

.page.section-home .banner-text {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.page.section-home .banner-text .profile-img {
    display: inline-block;
    margin-bottom: 30px;
}
.page.section-home .banner-text .profile-img img {
    width: 150px;
    height: 150px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #dfdfdf;
}

.page.section-home .banner-text .animate-title {
	margin: auto;
	font-size: 52px;
	text-transform: uppercase;
	line-height: 100%;
	color: #F5AE51;
	font-weight: 700;
	letter-spacing: 2px;
}

.page.section-home .banner-text h4 {
	font-size: 32px;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-family: "Open Sans", sans-serif;
	color: #fff;
}

.page.section-home #video-container {
	position: absolute !important;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.page.section-home #particles-js {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.page.section-home .banner-slider {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

/*======== 5. About Section ========*/

.section-about .about-img img {
	border: 10px solid rgba(255, 255, 255, 0.03);
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.section-about .about-info h3 {
	font-size: 26px;
	letter-spacing: 1px;
	font-weight: 600;
}

.section-about .about-info p {
	margin-bottom: 25px;
	line-height: 27px;
}

.section-about .about-info p strong {
	color: #e1e1e1;
}

.section-about .about-info .about-list li {
	margin-bottom: 10px;
}

.section-about .about-info .about-list li .title {
	position: relative;
	margin-right: 10px;
	padding-bottom: 1px;
	color: #a1a1a1;
	font-weight: 700;
}

.section-about .about-info .about-list li .title:before {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	left: 0;
	background: #e1e1e1;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.section-about .about-info .about-list li .title:after {
	position: absolute;
	content: ':';
	right: -8px;
}

.section-about .about-info .about-list li .value {
	position: relative;
}

.section-about .about-info .about-list li .value:before {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	left: 0;
}

.section-about .services .service-item {
	text-align: center;
	padding: 0 10px;
	margin-bottom: 40px;
}

.section-about .services .service-item i {
	font-size: 40px;
	margin-bottom: 15px;
	color: #e1e1e1;
}

.section-about .services .service-item .title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
}

.section-about .services .service-item p {
	font-size: 14px;
	margin-bottom: 0px;
}

.testimonials {
	margin-bottom: 100px;
}

.testimonials .testimonial-item {
	padding: 0px 15px 0px;
}

.testimonials .testimonial-item .description {
	background: #323338;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	font-size: 14px;
	font-style: italic;
	padding: 45px 35px;
	position: relative;
	-webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
	filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
}

.testimonials .testimonial-item .description p {
	margin-bottom: 0;
}

.testimonials .testimonial-item .description:before {
	content: "\f10d";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 0;
	font-size: 30px;
	color: #e1e1e1;
}

.testimonials .testimonial-item .description:after {
	content: "";
	width: 27px;
	position: absolute;
	bottom: -13px;
	border-top: 13px solid #353539;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
}

.testimonials .testimonial-item .testimonial-review {
	padding-top: 24px;
}

.testimonials .testimonial-item .testimonial-review img {
	-webkit-border-radius: 50%;
	border-radius: 50%;
	float: left;
	width: 60px;
	height: 60px;
	margin-left: 20px;
	margin-right: 20px;
}

.testimonials .testimonial-item .testimonial-review .testimonial-title {
	font-size: 18px;
	font-weight: 600;
	margin-top: 7px;
	float: left;
	text-transform: capitalize;
}

.testimonials .testimonial-item .testimonial-review .testimonial-title small {
	display: block;
	font-size: 16px;
	margin-top: 3px;
}

.testimonials .testimonial-item .testimonial-review .rating {
	padding: 0;
	list-style: none;
	text-align: right;
}

.testimonials .testimonial-item .testimonial-review .rating li {
	display: inline-block;
	color: #e1e1e1;
}

.clients .client-logo {
	border: 2px solid rgba(255, 255, 255, 0.03);
	padding: 10px;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
}

.clients .client-logo:hover {
	border-color: rgba(255, 255, 255, 0.7);
}

.clients .client-logo:hover img {
	opacity: 1;
}

.clients .client-logo img {
	width: 100px;
	height: 100px;
	margin: auto;
	opacity: .5;
	-webkit-transition: opacity .4s ease;
	-o-transition: opacity .4s ease;
	transition: opacity .4s ease;
}

/*======== 6. Resume Section ========*/

.section-resume .experience,
.section-resume .education {
	border-left: 4px solid #e1e1e1;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	background: rgba(255, 255, 255, 0.03);
	color: #e1e1e1;
	position: relative;
	padding: 50px;
	list-style: none;
	text-align: left;
	font-weight: 100;
	max-width: 90%;
	margin: 0 auto 50px auto;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.section-resume .experience h4,
.section-resume .education h4 {
	font-weight: 600;
	letter-spacing: .5px;
	font-size: 20px;
	text-transform: uppercase;
}

.section-resume .experience h5,
.section-resume .education h5 {
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
	text-transform: uppercase;
	color: #e1e1e1;
}

.section-resume .experience h5 .date,
.section-resume .education h5 .date {
	margin-right: 5px;
	padding-right: 10px;
	border-right: 3px solid #e1e1e1;
}

.section-resume .experience p,
.section-resume .education p {
	margin-bottom: 0;
	font-size: 16px;
}

.section-resume .experience .event,
.section-resume .education .event {
	border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
	padding-bottom: 25px;
	margin-bottom: 25px;
	position: relative;
}

.section-resume .experience .event:last-of-type,
.section-resume .education .event:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border: none;
}

.section-resume .experience .event:after,
.section-resume .education .event:after {
	position: absolute;
	display: block;
	top: 0;
}

.section-resume .experience .event:after,
.section-resume .education .event:after {
	-webkit-box-shadow: 0 0 0 4px #e1e1e1;
	box-shadow: 0 0 0 4px #e1e1e1;
	left: -57.85px;
	background: #313534;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	height: 11px;
	width: 11px;
	content: "";
	top: 5px;
}

.section-resume .skills {
	margin-bottom: 20px;
}

.section-resume .skills .skill-item {
	padding: 0 20px;
	margin-bottom: 30px;
}

.section-resume .skills .skill-item .progress-title {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.section-resume .skills .skill-item .progress {
	height: 5px;
	background-color: rgba(255, 255, 255, 0.03);
	overflow: visible;
}

.section-resume .skills .skill-item .progress .progress-bar {
	background-color: #e1e1e1;
	height: 100%;
	position: relative;
}

.section-resume .skills .skill-item .progress .progress-bar:after {
	position: absolute;
	content: "";
	padding: 5px;
	border: 4px solid #e1e1e1;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	right: 0;
	top: -7px;
	background-color: #2a2b30;
}

.section-resume .skills .skill-item .progress .progress-bar .progress-value {
	position: absolute;
	right: -8px;
	font-size: 14px;
	top: -30px;
	font-weight: 600;
}


/*======== 7. Portfolio Section ========*/

.section-portfolio .portfolio-filter {
	margin-bottom: 40px;
}

.section-portfolio .portfolio-filter ul {
	text-align: center;
	margin-bottom: 0px;
}

.section-portfolio .portfolio-filter ul li {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	cursor: pointer;
	position: relative;
	padding: 10px 25px;
	text-transform: uppercase;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

.section-portfolio .portfolio-filter ul li.active {
	background-color: #e1e1e1;
	-webkit-border-radius: 50px;
	border-radius: 50px;
	-webkit-box-shadow: 0px 10px 30px -4px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 10px 30px -4px rgba(0, 0, 0, 0.2);
	color: #000;
}

.section-portfolio .portfolio-items .item figure {
	position: relative;
	text-align: center;
	cursor: pointer;
	background: #e1e1e1;
	margin-bottom: 20px;
	overflow: hidden;
}

.section-portfolio .portfolio-items .item figure img {
	opacity: 1;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, -webkit-transform 0.35s;
	-o-transition: opacity 0.35s, transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
}

.section-portfolio .portfolio-items .item figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 24px;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.section-portfolio .portfolio-items .item figure figcaption a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.section-portfolio .portfolio-items .item figure figcaption:before {
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	content: '';
	-webkit-transition: -webkit-transform 0.6s;
	transition: -webkit-transform 0.6s;
	-o-transition: transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
	background-color: rgba(255, 255, 255, 0.5);
}

.section-portfolio .portfolio-items .item figure figcaption h3 {
	text-align: left;
	opacity: 0;
	-webkit-transition: opacity 0.35s;
	-o-transition: opacity 0.35s;
	transition: opacity 0.35s;
	font-size: 20px;
	font-weight: 700;
}

.section-portfolio .portfolio-items .item figure figcaption p {
	position: absolute;
	right: 0;
	bottom: 0;
	margin: 18px;
	font-size: 14px;
	padding: 0 10px;
	-webkit-transform: translateX(200%);
	-ms-transform: translateX(200%);
	transform: translateX(200%);
	border-right: 4px solid #fff;
	text-align: right;
	opacity: 0;
	-webkit-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.section-portfolio .portfolio-items .item figure figcaption i {
	position: absolute;
	left: 0px;
	-webkit-transform: translateX(-200%);
	-ms-transform: translateX(-200%);
	transform: translateX(-200%);
	margin: 18px;
	font-size: 24px;
	opacity: 0;
	bottom: 0;
	-webkit-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.section-portfolio .portfolio-items .item figure:hover img {
	opacity: 0.25;
	-webkit-transform: scale3d(1.05, 1.05, 1);
	transform: scale3d(1.05, 1.05, 1);
}

.section-portfolio .portfolio-items .item figure:hover figcaption:before {
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 130%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 130%, 0);
}

.section-portfolio .portfolio-items .item figure:hover h3 {
	opacity: 1;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.section-portfolio .portfolio-items .item figure:hover p {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
}

.section-portfolio .portfolio-items .item figure:hover i {
	opacity: 1;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}


/*======== 8. Blogs Section ========*/

.section-blog .blog-item {
	display: block;
	margin-bottom: 50px;
	margin-top: 30px;
	background-color: rgba(255, 255, 255, 0.03);
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	text-align: center;
	color: #e1e1e1;
}

.section-blog .blog-item:hover .title {
	text-decoration: underline !important;
}

.section-blog .blog-item .post-img {
	width: 80%;
	margin: 0 auto;
	position: relative;
	top: -30px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.section-blog .blog-item .post-content {
	position: relative;
	top: -20px;
	padding: 0 20px;
}

.section-blog .blog-item .post-content .cat {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	color: #e1e1e1;
}

.section-blog .blog-item .post-content .title {
	font-size: 24px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.section-blog .blog-item .post-content .meta .date {
	font-size: 14px;
	color: #7b7b7b;
}


/*======== 9. Contact Section ========*/

.section-contact {
	text-align: center;
}

.section-contact .contact-detail h3 {
	font-size: 32px;
	font-weight: 500;
}

.section-contact .contact-detail p {
	margin-bottom: 10px;
}

.section-contact .contact-detail .contact-info li {
	margin-bottom: 10px;
}

.section-contact .contact-detail .contact-info li .title {
	display: block;
	font-size: 18px;
	font-weight: 800;
	display: inline-block;
	position: relative;
}

.section-contact .contact-detail .contact-info li .title:before {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	left: 0%;
	background: #e1e1e1;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.section-contact .contact-detail .contact-info li .value {
	display: block;
	font-size: 14px;
}

.section-contact .contact-detail .social-media h4 {
	font-size: 18px;
	font-weight: 800;
	position: relative;
	font-family: "Open Sans", sans-serif;
	line-height: 1.5em;
	display: inline-block;
}

.section-contact .contact-detail .social-media h4:before {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	left: 0;
	background: #e1e1e1;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.section-contact .contact-detail .social-media .social-media-icons {
	margin-top: 5px;
}

.section-contact .contact-detail .social-media .social-media-icons a {
	display: inline-block;
	margin: 0 10px;
	font-size: 24px;
	color: #a1a1a1;
}

.section-contact .contact-detail .social-media .social-media-icons a:hover {
	color: #e1e1e1;
}

.section-contact .google-map {
	margin-top: 50px;
	margin-bottom: 50px;
}

.section-contact .google-map #map {
	height: 400px;
}


/*======== 10. Blog Page Css ========*/

header .back-button {
	position: absolute;
	display: inline-block;
	top: 20px;
	right: 20px;
	height: 50px;
	width: 50px;
	z-index: 1000;
	cursor: pointer;
	padding: 10px 8px;
	color: #fff;
}

header .back-button:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #e1e1e1;
	opacity: .6;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	z-index: -1;
}

header .back-button i {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 30px;
}

.single-blog {
	background-color: #2a2b30;
	position: relative;
	overflow-y: scroll;
	height: 100%;
}

.single-blog .blog-category {
	text-align: center;
}

.single-blog .blog-category li {
	display: inline-block;
	position: relative;
	border-right: 2px solid #e1e1e1;
	padding-right: 10px;
	padding-left: 5px;
}

.single-blog .blog-category li:last-child {
	border: none;
	padding-right: 0px;
}

.single-blog .blog-category li a {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
	font-size: 14px;
	color: #e1e1e1;
}

.single-blog .blog-meta {
	font-size: 14px;
	color: #a1a1a1;
	font-weight: 600;
}


/*======== 11. Mobile Responsive Css ========*/

@media screen and (max-width: 991px) {
	.section-home .banner-text .text-slideshow .animate-title {
		font-size: 58px;
	}
	.section-about .about-img {
		margin-bottom: 40px;
	}
	.section-contact .contact-detail {
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 700px) {
	header .nav-menu .nav-menu--link {
		margin-left: 8px;
		margin-right: 8px;
	}
	header .social-links,
	header .copy {
		display: none;
	}
	.section-home .banner-text h4 {
		font-size: 30px !important;
	}
	.section-home .banner-text .text-slideshow .animate-title {
		font-size: 50px;
	}
}

@media screen and (max-width: 620px) {
	header .nav-menu .nav-menu--link {
		display: block;
		margin-bottom: 15px;
	}
	header .nav-menu .nav-menu--link span {
		font-size: 14px;
	}
	header .nav-menu .nav-menu--link i {
		display: none;
	}
	header .nav-menu .social-links,
	header .nav-menu .copy {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.section-home .banner-text .text-slideshow .animate-title {
		font-size: 46px;
	}
}

@media screen and (max-width: 550px) {
	.section-home .banner-text h4 {
		font-size: 24px !important;
	}
	.section-home .banner-text .text-slideshow .animate-title {
		font-size: 36px;
	}
	.section-about .client-logo {
		margin: 0 10px;
	}
}

@media screen and (max-width: 480px) {
	.page-heading h2:before {
		font-size: 52px;
	}
	.sub-heading h3:before,
	.sub-heading h3:after {
		width: 60px;
	}
	.section-resume .education,
	.section-resume .experience {
		padding-left: 25px;
		padding-right: 25px;
	}
	.section-resume .education .event:after,
	.section-resume .experience .event:after {
		left: -32.85px;
	}
}

@media screen and (max-height: 350px) {
	header .copy {
		display: none;
	}
}

@media screen and (min-width: 701px) and (max-height: 400px) {
	header .copy,
	header .social-links {
		display: none;
	}
}

@media screen and (max-width: 700px) and (max-height: 400px) {
	header .nav-menu {
		display: block;
		min-height: 30vh;
		overflow-y: scroll;
	}
}