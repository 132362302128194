.sec__head {
  margin-top: 6rem;
  margin-bottom: 2rem;
  font-weight: bolder;
  text-decoration-thickness: 500;
}

.row{
  margin-left: 0 !important;
  margin-right: 0 !important;

}

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}

.features p {
  color: rgb(17, 83, 198);
  font-weight: bold;
  cursor: pointer;
}

.featured__title h2 {
  font-weight: bolder;
  margin-top: 2rem;
  text-align: center;
}

video {
  display: block;
  margin: 0 auto;
}

.mvideo{
  display: block;
  /* margin: 50% auto; */
}
.sepads img, #tsmarriageoutline img{
width: 90vw;
}


@media screen and (max-width:991px) {
  .offcanvas.offcanvas-end {
    width: 20vw;
  }
  /* .sepads{
    width: 50px;
    height: 50px;
} */
.sec__head{
  font-size:medium !important;
}
}


@media screen and (max-width:768px) {
  .accordion-body iframe{
  width: 74vw;
  height: 245px !important;
}
   
video{
  width: 90vw;
}
   
.mvideo{
  width: 90vw;
}


.btn-group {
  display: inline !important;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.btn-group > .btn {
  padding: 0.6rem 0.4rem !important;
  margin-bottom: 0.5rem !important;
}

  .offcanvas.offcanvas-end {
    width: 45vw;
  }
  .sepads h2, #tsmarriageoutline h2{
    font-size: 1rem;
  }
   .sepads img, #tsmarriageoutline img{
width: 80vw;
}
/* video{
    /* Rotate the content container *
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height *
    width: 90vh;
    /* Set content height to viewport width *
    height: 90vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 5%;
  } */
}