.counts {
  padding-bottom: 30px;
}

.counts .count-box {
  box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  background: #fff;
  z-index: 3;
  margin-bottom: 30px;
}

.counts .count-box i {
  display: block;
  font-size: 64px;
  margin-bottom: 15px;
}

.counts .count-box span {
  font-size: 42px;
  display: block;
  font-weight: 700;
  color: #1c5c93;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}
